import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import DataSenderContainerV1 from "../components/FormBuilder/DataSenderContainer/DataSenderContainerV1";
import "../styles/form.scss";

const DataSender = () => (
  <AuthenticationLayout>
    <DataSenderContainerV1 />
  </AuthenticationLayout>
);

export default DataSender;
