import React from "react";
import { format } from "date-fns";
import { DataSenderModel } from "../../../../store/DataSender/actions";
import "./index.scss";

interface DataSenderItemProps {
  dataSender: DataSenderModel;
  onSelect: (dataSender: DataSenderModel) => void;
}

const DataSenderItem = ({ dataSender, onSelect }: DataSenderItemProps) => (
  <div className="dataSenderListItem">
    <div className="dataSenderListItemInnerContainer">
      <div className="dataSenderListItemInnerContainerLeft">
        <h4>{dataSender.name}</h4>
        <p>{format(new Date(dataSender.created_at), "MM/dd/yyyy")}</p>
      </div>
      <div className="dataSenderListItemInnerContainerRight">
        <button type="button" onClick={() => onSelect(dataSender)}>
          Select Data Sender
        </button>
      </div>
    </div>
  </div>
);

export default DataSenderItem;
