import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../store";
import {
  DataSenderDataModel,
  DataSenderModel,
  DataSenderObject,
  getAllDataSenders,
  getCustomerDataSender,
  getCustomerDataSenderData,
  sendCustomerDataSender,
} from "../../../store/DataSender/actions";
import {
  resetDataStates,
  setDataSenderObject,
  setErrors,
} from "../../../store/DataSender/reducer";
import {
  selectAllDataSenders,
  selectDataSenderData,
  selectDataSenderErrors,
  selectDataSenderLoading,
  selectDataSenderObject,
  selectSuccessfullySentData,
} from "../../../store/DataSender/selector";
import { resetSurvey } from "../../../store/Surveys/reducer";
import "../../../styles/fields.scss";
import {
  filterSendThroughKeys,
  missingRequiredFields,
  sendToast,
} from "../../../utils/helpers";
import DefaultButton from "../../Buttons/DefaultButton";
import LoadingSmall from "../../Loading/LoadingSmall";
import TablePagination from "../../Pagination/TablePagination";
import ModalContent from "../components/ModalContent";
import useDefaultFormBuilder from "../FormHook/defaultFormHook";
import { dataSenderFields } from "./config";
import DataSenderItem from "./DataSenders/DataSenderItem";
import "./index.scss";
import { testConfig, testUserData } from "./TestData/test_data";

const DataSenderContainerV1 = () => {
  const dispatch = useAppDispatch();
  const [config, setConfig] = useState(null) as any[];
  const [fields, data] = useDefaultFormBuilder({ config });
  const [showSelectDataSender, setShowSelectDataSender] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDataSenders, setShowDataSenders] = useState(false);
  const success = useSelector(state =>
    selectSuccessfullySentData(state)
  ) as boolean;
  const loading = useSelector(state =>
    selectDataSenderLoading(state)
  ) as boolean;
  const errors = useSelector(state => selectDataSenderErrors(state));

  const dataSenderRequestObject = useSelector(state =>
    selectDataSenderObject(state)
  ) as DataSenderModel;
  const dataSenderData = useSelector(state =>
    selectDataSenderData(state)
  ) as DataSenderDataModel;
  const allDataSenders = useSelector(state =>
    selectAllDataSenders(state)
  ) as DataSenderObject;

  useEffect(() => {
    dispatch(getAllDataSenders(currentPage));
  }, [currentPage]);

  useEffect(() => {
    if (success) {
      dispatch(resetDataStates());
      dispatch(setErrors(null));
      navigate("/success/", {
        state: {
          type: "DataSender",
        },
      });
    }
  }, [success]);

  useEffect(() => {
    if (errors) {
      const errorString = errors.join(", ");
      sendToast(errorString, { type: "error" });
      dispatch(resetSurvey());
    }
  }, [errors]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search) as any;
      const entries = [...urlParams.entries()];

      const { vid } = entries.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      if (!vid) {
        toast("No Vid was attached");
        navigate('/404/', {
          state: {
            error: "There was an error with your form. Please contact a Clever representative at 833-7-CLEVER",
          },
        });
      } else if (process.env.NODE_ENV === "development") {
        const testDataSenderConfig = testConfig as any;
        const testDataSenderData = testUserData as any;
        const config = dataSenderFields(
          testDataSenderData,
          testDataSenderConfig
        );
        setConfig(config);
      } else {
        dispatch(getCustomerDataSender(vid));
        dispatch(getCustomerDataSenderData(vid));
      }
    }
  }, []);

  useEffect(() => {
    if (dataSenderData && dataSenderRequestObject) {
      const config = dataSenderFields(dataSenderData, dataSenderRequestObject);
      setConfig(config);
    }
    if (!dataSenderRequestObject && dataSenderData) {
      // show ALL data senders
      setShowSelectDataSender(true);
    }
  }, [dataSenderData, dataSenderRequestObject]);

  const handleFormSubmit = () => {
    if (
      process.env.NODE_ENV === "development" ||
      (data && dataSenderRequestObject)
    ) {
      const urlParams = new URLSearchParams(window.location.search) as any;
      const entries = [...urlParams.entries()];

      const sender_id =
        process.env.NODE_ENV === "development"
          ? testConfig.id
          : dataSenderRequestObject.id;

      const newData: any = filterSendThroughKeys(config, data);
      const missingFields = missingRequiredFields(config, newData);

      if (missingFields.length > 0) {
        const constructedString = missingFields
          .map(missingField => `Missing Required Field: ${missingField}`)
          .join("\n");
        sendToast(constructedString, { type: "error" });
        return;
      }
      const { vid } = entries.reduce(
        (acc, [key, value]) => {
          if (key.includes("agent_")) {
            acc.agentInfo[key] = value;
          } else {
            acc[key] = value;
          }
          return acc;
        },
        { agentInfo: {} }
      );
      const params = {
        data: { ...newData },
        vid,
        data_sender_id: sender_id,
      };
      if (process.env.NODE_ENV === "development") {
        // Log params if development
        console.log(params);
      } else {
        dispatch(sendCustomerDataSender(params));
      }
    } else if (!dataSenderRequestObject) {
      sendToast("Missing Data Sender, please select a valid data sender.", {
        type: "warning",
      });
    } else {
      sendToast(
        "There is missing data, please enter all the correct information",
        { type: "error" }
      );
    }
  };

  const selectDataSender = () => {
    setShowDataSenders(true);
  };

  const handleSelect = (dataSender: DataSenderModel) => {
    dispatch(setDataSenderObject(dataSender));
    setShowDataSenders(false);
  };

  return (
    <>
      <div className="defaultFormContainer">
        <h2>Data Sender</h2>

        <div className="defaultFormBody">
          <p>Enter all the fields below and click Submit.</p>
          {showSelectDataSender && (
            <>
              <p className="dataSenderSelectText">
                You can select a data sender below
              </p>
              <DefaultButton
                className="selectDataSenderButton"
                text="Select Data Sender"
                callback={() => selectDataSender()}
              />
            </>
          )}
          <div>
            {fields}
            <DefaultButton
              text={loading ? <LoadingSmall /> : "submit"}
              className="defaultButton"
              callback={() => handleFormSubmit()}
            />
          </div>
        </div>
      </div>
      {showDataSenders && allDataSenders && (
        <ModalContent
          title="Select Data Sender"
          onClose={() => setShowDataSenders(false)}
        >
          <div className="dataSenderList">
            <div className="dataSenderListHeader">
              <p>Title</p>
              <p>Action</p>
            </div>
            {allDataSenders.results.map((dataSender: DataSenderModel) => (
              <DataSenderItem
                dataSender={dataSender}
                key={dataSender.id}
                onSelect={(ds: DataSenderModel) => handleSelect(ds)}
              />
            ))}
            <TablePagination
              count={allDataSenders.count}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </ModalContent>
      )}
    </>
  );
};

export default DataSenderContainerV1;
