import React from "react";
import "./index.scss";
import { BackIcon, CloseIcon } from "../icons";

const ModalContent = ({ title, children, onClose }: any) => (
  <form className="modalContentContainer">
    <div className="modalContentInnerContainer">
      <header className="modalContentHeader">
        <button
          className="modalContentBackButton"
          onClick={() => onClose()}
          type="button"
        >
          <BackIcon className="small" />
          Back
        </button>

        <h3 className="modalContentTitle">{title}</h3>

        <button
          className="modalContentCloseButton"
          onClick={() => onClose()}
          type="button"
        >
          Close
          <CloseIcon className="small" />
        </button>
      </header>

      <div className="modalContentChildren">{children}</div>
    </div>
  </form>
);

export default ModalContent;
