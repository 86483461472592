import * as React from "react";

const BackIcon = ({ className }: any) => (
  <svg
    width="24"
    viewBox="0 0 256 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M192 448c-8 0-16-3-23-9L9 279a32 32 0 0 1 0-46L169 73a32 32 0 1 1 46 46L77 256l138 137a32 32 0 0 1-23 55z"
      fill="currentColor"
    />
  </svg>
);

export default BackIcon;
